<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'

const props = withDefaults(
  defineProps<{
    bannerImagePaths: string[]
    buttonTitle?: string | null
    path?: string | null
    title: string
  }>(),
  { buttonTitle: null, path: null }
)

const router = useRouter()

async function openPath (): Promise<void> {
  const { path } = props

  if (path) {
    await router.push(path)
  }
}
</script>

<template>
  <div v-if="bannerImagePaths.length" class="thin-banner" :class="{ 'thin-banner_has-path': path }" @click="openPath">
    <div class="thin-banner__desktop-banner">
      <img v-lazy="bannerImagePaths[0]" :alt="title" loading="lazy">
    </div>
    <div class="thin-banner__mobile-banner">
      <img v-lazy="bannerImagePaths[1]" :alt="title" loading="lazy">
    </div>

    <BasicButton
      v-if="buttonTitle"
      class="thin-banner__button"
      color="white"
      is-small
      :title="buttonTitle"
      @click="openPath"
    />
  </div>
</template>

<style lang="scss" scoped>
$maxWidth: 600px;

.thin-banner {
  position: relative;

  &_has-path {
    cursor: pointer;
  }

  &__button {
    bottom: 24px;
    left: 24px;
    position: absolute;

    @media screen and (max-width: $maxWidth) {
      bottom: 16px;
      left: 16px;
    }
  }

  &__desktop-banner,
  &__mobile-banner {
    background: rgba(0, 0, 0, 0.0625);
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
    }
  }

  &__desktop-banner {
    height: 185px;

    @media screen and (max-width: $maxWidth) {
      display: none;
    }
  }

  &__mobile-banner {
    min-height: 120px;

    @media screen and (min-width: $maxWidth) {
      display: none;
    }
  }
}
</style>
